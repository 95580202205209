import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate для перенаправления
import ServerContainer from './ServerContainer/ServerContainer';
import './Workspace.css';

function Workspace() {
  const [username, setUsername] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate(); // Используем useNavigate для перенаправления

  // Получение имени пользователя
  const fetchUsername = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Токен не найден');
      return;
    }

    try {
      const response = await fetch('https://gordeev-clouds.ru/api/get_username', {
        headers: {
          'Authorization': `Bearer ${token}`,  // Добавляем Bearer перед токеном
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setUsername(data.username); // Предполагается, что в ответе есть поле "username"
      } else {
        console.error('Ошибка при получении имени пользователя');
      }
    } catch (error) {
      console.error('Ошибка при получении имени пользователя:', error);
    }
  }, []); // Пустой массив зависимостей гарантирует, что функция не будет пересоздана

  useEffect(() => {
    fetchUsername();
  }, [fetchUsername]); // Добавляем fetchUsername в зависимости

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Удаляем токен
    navigate('/'); // Перенаправляем на страницу входа
  };

  return (
    <div className="workspace-container">
      <header className="workspace-header">
        <div className="username-section">
          <span className="username">{username}</span>
          <button className="dropdown-toggle" onClick={toggleMenu}>
            &#x25BC;
          </button>
        </div>
        {menuOpen && (
          <div className="dropdown-menu">
            <ul>
              <li>Настройки</li>
              <li>Пополнить баланс</li>
              <li onClick={handleLogout}>Выход</li> {/* Клик по выходу */}
            </ul>
          </div>
        )}
      </header>
      <ServerContainer />
    </div>
  );
}

export default Workspace;
