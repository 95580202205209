import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home/Home';
import Workspace from './components/Workspace/Workspace';

function App() {
  const isAuthenticated = !!localStorage.getItem('token'); // Проверка наличия токена

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route 
          path="/workspace" 
          element={isAuthenticated ? <Workspace /> : <Navigate to="/" />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
