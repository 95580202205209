import React, { useState, useEffect } from 'react';
import './ServerContainer.css';

function ServerContainer() {
    const [showCreateServerModal, setShowCreateServerModal] = useState(false);
    const [servers, setServers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Новые состояния для полей создания сервера
    const [serverName, setServerName] = useState('');
    const [ramGb, setRamGb] = useState(2);  // Начальное значение ОЗУ
    const [cores, setCores] = useState(1);  // Начальное значение ядер
    const [storageGb, setStorageGb] = useState(5);  // Начальное значение памяти

    // Функция для получения серверов
    const fetchServers = async () => {
        const token = localStorage.getItem('token');  // Извлекаем токен
        console.log('Токен:', token);
        
        if (!token) {
            console.error('Токен не найден');
            setError('Токен не найден');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://gordeev-clouds.ru/api/user/servers', {
                headers: {
                    'Authorization': `Bearer ${token}`,  // Передаем токен с префиксом Bearer
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setServers(data.servers);
                setLoading(false);
            } else {
                console.error('Ошибка при загрузке серверов:', response.statusText);
                setError('Ошибка при загрузке серверов');
                setLoading(false);
            }
        } catch (error) {
            console.error('Ошибка при загрузке серверов:', error);
            setError('Ошибка при загрузке серверов');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchServers();
    }, []);

    const handleAddServerClick = () => {
        setShowCreateServerModal(true);
    };

    const handleCloseModal = () => {
        setShowCreateServerModal(false);
    };

    const handleCreateServer = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('Токен не найден');
            return;
        }

        const newServer = {
            name: serverName,
            storage_gb: storageGb,
            cores: cores,
            ram_gb: ramGb,
        };

        try {
            const response = await fetch('https://gordeev-clouds.ru/api/servers', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,  // Передаем токен с префиксом Bearer
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newServer)
            });

            if (response.ok) {
                // Закрываем модальное окно и обновляем список серверов
                setShowCreateServerModal(false);
                fetchServers();
            } else {
                console.error('Ошибка при создании сервера:', response.statusText);
            }
        } catch (error) {
            console.error('Ошибка при создании сервера:', error);
        }
    };

    return (
        <div className="server-container">
            <div className="server-card">
                <h2>Ваши сервера</h2>
                {loading ? (
                    <p>Загрузка серверов...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : servers.length > 0 ? (
                    <ul className="server-list">
                        {servers.map(server => (
                            <li key={server.id} className="server-item">
                                <h3>{server.name}</h3>
                                <p>Память: {server.storage_gb} GB</p>
                                <p>Ядра: {server.cores}</p>
                                <p>ОЗУ: {server.ram_gb} GB</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Серверов нет, добавить новые.</p>
                )}
                <button onClick={handleAddServerClick}>Добавить сервер</button>
            </div>

            {showCreateServerModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Создание нового сервера</h2>
                        <form onSubmit={handleCreateServer}>
                            <div>
                                <label>Название сервера</label>
                                <input
                                    type="text"
                                    value={serverName}
                                    onChange={(e) => setServerName(e.target.value)}
                                    placeholder="Введите название сервера"
                                    required
                                />
                            </div>
                            <div>
                                <label>ОЗУ (GB)</label>
                                <input
                                    type="range"
                                    min="2"
                                    max="4"
                                    value={ramGb}
                                    onChange={(e) => setRamGb(parseInt(e.target.value))}
                                />
                                <span>{ramGb} GB</span>
                            </div>
                            <div>
                                <label>Ядра</label>
                                <input
                                    type="range"
                                    min="1"
                                    max="4"
                                    value={cores}
                                    onChange={(e) => setCores(parseInt(e.target.value))}
                                />
                                <span>{cores} ядра(ов)</span>
                            </div>
                            <div>
                                <label>Память (GB)</label>
                                <input
                                    type="range"
                                    min="5"
                                    max="15"
                                    value={storageGb}
                                    onChange={(e) => setStorageGb(parseInt(e.target.value))}
                                />
                                <span>{storageGb} GB</span>
                            </div>
                            <button type="submit">Создать</button>
                            <button type="button" onClick={handleCloseModal}>
                                Закрыть
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ServerContainer;
