import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import logo from '../../assets/images/gordeev-clouds black.png';
import wallpaper from '../../assets/images/media/wallpaper.gif';  // Импортируем GIF
import Login from '../Login/Login';

function Home() {
  const [showLogin, setShowLogin] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [typing, setTyping] = useState(true);

  const indexRef = useRef(0);  // Храним текущее слово
  const charIndexRef = useRef(0);  // Храним текущий индекс символа

  const handleLogoClick = () => {
    setShowLogin(true);  // Отображаем окно Login при клике
  };

  useEffect(() => {
    const words = ["GORDEEV-CLOUDS", "Хостинг", "Разработка","Создать новый сервер","VPS HOSTING","MAKE NEW SERVER"];  // Перемещаем сюда

    const typeEffect = () => {
      if (typing) {
        const currentWord = words[indexRef.current];
        const currentSubstring = currentWord.substring(0, charIndexRef.current + 1);

        if (displayText === currentWord.substring(0, charIndexRef.current)) {
          setDisplayText(currentSubstring);
          charIndexRef.current++;
        }

        if (charIndexRef.current === currentWord.length) {
          setTyping(false);
          setTimeout(() => {
            setTyping(true);
          }, 5000);
        }
      } else {
        if (charIndexRef.current > 0) {
          setDisplayText(prev => prev.slice(0, -1));
          charIndexRef.current--;
        } else {
          setTyping(true);
          indexRef.current = (indexRef.current + 1) % words.length;
          charIndexRef.current = 0;
          setDisplayText('');
        }
      }
    };

    const interval = setInterval(typeEffect, typing ? 150 : 100);

    return () => clearInterval(interval);
  }, [typing, displayText]);

  return (
    <div className="home-container" style={{ backgroundImage: `url(${wallpaper})` }}>  {/* Применяем фон */}
      <div className="animated-text">
        {displayText}<span className="cursor"></span>
      </div>
      <img
        src={logo}
        alt="Gordeev Clouds Logo"
        className="home-logo"
        onClick={handleLogoClick}
      />
      {showLogin && (
        <div className="login-modal">
          <Login />
        </div>
      )}
    </div>
  );
}

export default Home;
